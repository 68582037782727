import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment";
import Layout from "../components/layout";
import Seo from "../components/seo";
import _ from 'lodash';
import HeroImage from "../components/HeroImage/heroimage";
import { withPrefix } from "gatsby"
import MDRender from "../components/mdrender"
const isBrowser = typeof document !== "undefined"
import Parts from '../components/parts';
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import BlogList from '../components/bloglist'


export default function Page({ data }) {

    let mapPagedata =  _.merge( _.pick(data.page,['id','date', 'description','title','slug','keywords']),
                              _.pick(data.page.body.data,['body']),
                              { image: data.page.image.localFile })
    let partsData = _.orderBy( data.page.parts.map( item => {
          //console.debug( item );
          return _.merge( _.pick( item, ['id','title','order','page','layouttype','partId','anchor','options']),
                          { image: (item.image) ? item.image.localFile : null },
                          { text: (item.text && item.text.data ) ? ( item.text.data.text ) : "" })
                }),["order"],["asc"]  )
    //const useHero = ( mapPagedata.image !==null)
     //console.debug(mapPagedata )
    const useHero = false;
    if (useHero) { return (
      <Layout header="Page" >
        <Seo  title={ mapPagedata.title } descripton={mapPagedata.descripton}  keywords={mapPagedata.keywords} />
        <div className="site-container">
          <div className="container">
             ( <HeroImage title={mapPagedata.title} description={mapPagedata.description} image={withPrefix(mapPagedata.image.childImageSharp.gatsbyImageData.images.fallback.src)} />
              <div className="details">
                <span className="date">
                  <i className="fas fa-calendar-alt"></i>{" "}
                    {moment(mapPagedata.date,  "YYYY-MM-DD").format("LL")}
                </span>
                <div>
                    <MDRender mdstring={mapPagedata.body} />
                </div>
              </div>
          </div>
        </div>
      </Layout>
    ) } else {
      const image = getImage(withPrefix(mapPagedata.image));
      const bgImage = convertToBgImage(image);
      return (
      <Layout header="Page" >
        <Seo  title={ mapPagedata.title } descripton={mapPagedata.descripton}  keywords={mapPagedata.keywords} />
        <div className="site-container">
          <div className="container">


          <div className="page">
                <BackgroundImage        Tag="section"   {...bgImage}     preserveStackingContext       >
                  <div className="page-content text-white overlay overlay-dark p-3">
                    <h1 className="title">{mapPagedata.title}</h1>
                    <h2 className="description">{mapPagedata.description}</h2>
                    <span className="date">
                      <i className="fas fa-calendar-alt"></i>{" "}
                        {moment(mapPagedata.date,  "YYYY-MM-DD").format("LL")}
                    </span>
                    <div className="row">
                        <div className="col page-details">
                          <div className="page-body ">
                              <MDRender mdstring={mapPagedata.body} />
                          </div>
                        </div> {/*blog-details */}

                        <div className="col page-detail">
                            <GatsbyImage  className="page-image"  image={image} alt="image" objectFit="cover" objectPosition="50% 50%" />
                        </div>
                    </div> {/* row */}
                    <div className="row page-footer p-3">
                      <div className="col page-parts">
                          <Parts key="Parts" anchor="parts" parts={partsData}/>
                      </div>
                    </div>
                  </div> {/*page-content*/}
                </BackgroundImage>
          </div>  {/* page-post */}





          </div>
        </div>
      </Layout>
    );
  };
}

export const pageQuery = graphql`
query ($id: String!) {
  page: strapiPage(id: { eq: $id }) {
      id
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      page
      section
      slug
      title
      date
      description
      keywords
      body {
        data {
          body
        }
      }
      parts {
        id
        order
        page
        title
        text {
          data {
            text
          }
        }
        layouttype
        partId
        anchor
        options {
          parallax
          height
          centre
          centretitle
          margin          
        }
        image {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
      }
    }
}
`;
